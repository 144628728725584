import { useState, useEffect, useRef } from 'react'

let loadedMaps = false

/**
 * Loads the Google Maps JS API and instantiates a new map
 *
 * Note: we don't re-instantiate the map, so make sure to provide
 *       the correct key, ref and opts at the first call.
 */
export const useMaps = (key, targetRef, opts) => {

  let [map, setMap] = useState(null)

  useEffect(() => {
    const onLoad = () => {
      loadedMaps = true
      const map = new window.google.maps.Map(targetRef.current, opts)
      setMap(map)
    }
    if (loadedMaps) {
      onLoad()
    } else {
      const cbName = 'GMapsCb' + Date.now()
      window[cbName] = onLoad
      const script = document.createElement("script")
      script.setAttribute('async', 'true')
      script.src = 'https://maps.googleapis.com/maps/api/js'
        + `?key=${key}&callback=${cbName}`
      document.getElementsByTagName("head")[0].appendChild(script)
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  return map
}

export const useMapMarkers = (map, locs) => {
  const markers = useRef([])

  useEffect(() => {
    if (!map || !locs) return
    const newMarkers = locs.map(loc => {
      const marker = new window.google.maps.Marker({
        ...loc,
        map
      })
      if (loc.onClick) {
        marker.addListener('click', loc.onClick)
      }
      return marker
    })

    // Clear old markers
    markers.current.forEach(marker => marker.setMap(null))
    markers.current = newMarkers

    // Fit map to bounds
    if (locs.length) {
      const bounds = new window.google.maps.LatLngBounds()
      locs.forEach(l => bounds.extend(l.position))
      map.fitBounds(bounds)
      map.panToBounds(bounds)
    }

  }, [map, locs])

}
