import React from 'react'
import PropTypes from 'prop-types'

import './NumberSelector.sass'

const NumberSelector = ({ min = 0, max = 10, value = min, onChange }) => {

  const handleMinus = () => {
    if (value > min) {
      const newValue = value - 1
      return onChange(newValue)
    }
  }
  const handlePlus = () => {
    if (value < max) {
      const newValue = value + 1
      return onChange(newValue)
    }
  }

  return (
    <div className="number-selector">
      <span className="handler minus sol-icon sol-icon-minus" onClick={handleMinus} />
      <span>{value}</span>
      <span className="handler plus  sol-icon sol-icon-plus" onClick={handlePlus} />
    </div>
  )
}

NumberSelector.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func
}

export default NumberSelector
