import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { registerModal } from '@gluedigital/modal'
import { connect } from 'react-redux'
import { hideDisplay, showDisplay } from '../../store/actions/display'
import { setHotel } from '../../store/actions/selection'
import { useMaps, useMapMarkers } from '../../util/useMaps'
import { FormattedMessage } from 'react-intl'
import { hotelFilter } from '../../util/filters'

import './MapModal.sass'

export const MapModal = props => {
  const {
    gmapsKey, aceptList,
    type, query, results, staticList,
    showList, hideDisplay, setHotel
  } = props
  const mapContainer = useRef(null)

  let hotelList = []
  if (type === 1 && results) {
    ['hotels', 'destination', 'thematic'].forEach(k => {
      hotelList = hotelList.concat(results[k].listGroup)
    })
  }
  if (type === 2 && staticList) hotelList = staticList.filter(hotelFilter(query.label))

  const hotels = hotelList && hotelList.map(r => {
    const loc = r.address.geoLocation
    return {
      position: { lat: loc.latitude, lng: loc.longitude },
      title: r.name,
      onClick: () => {
        setHotel({ type: 'hotel', hotel: r, label: r.name })
        hideDisplay()
      }
    }
  })

  const map = useMaps(gmapsKey, mapContainer, {
    center: { lat: 40, lng: -4 },
    zoom: 5,
    disableDefaultUI: true,
    zoomControl: true,
    maxZoom: 18
  })
  useMapMarkers(map, hotels)

  const listLink = !aceptList ? '' :
    <div className="link-list" onMouseDown={() => setTimeout(showList, 0)}>
      <FormattedMessage id="search.list" />
    </div>

  return (
    <div className="modal-content map-modal">
      <h3 className="select-title only-vertical">
        <FormattedMessage id="list-modal.select-title" />
      </h3>
      {listLink}
      <div id="map" ref={mapContainer} onMouseDown={e => e.preventDefault() } />
    </div>
  )
}

MapModal.propTypes = {
  type: PropTypes.number,
  query: PropTypes.object.isRequired,
  results: PropTypes.object,
  staticList: PropTypes.array,
  gmapsKey: PropTypes.string.isRequired,
  showList: PropTypes.func.isRequired,
  hideDisplay: PropTypes.func.isRequired,
  aceptList: PropTypes.bool
}

const mapStateToProps = s => {
  const cmp = s.api.config && s.api.config.components
  const ac = s.api.autocomplete && s.api.autocomplete.value
  return {
    gmapsKey: cmp && cmp.googleMapConfig && cmp.googleMapConfig.apikeyGoogle,
    type: cmp && cmp.listType,
    query: s.selection.hotel,
    results: ac,
    staticList: s.api.config && s.api.config.simpleHotelListWidget,
    aceptList: cmp && cmp.aceptList
  }
}

const mapDispatchToProps = {
  showList: () => showDisplay('search-list', true),
  setHotel,
  hideDisplay
}

registerModal('search-map', connect(mapStateToProps, mapDispatchToProps)(MapModal))
