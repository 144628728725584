export const validateRooms = (rooms) => {
  if (!rooms.some(r => r.adults > 0)) {
    // There must be at least one adult per reservation
    return 'min-adults'
  }
  if (rooms.some(r => (r.kids || 0) + (r.adults || 0) === 0)) {
    // You can't book an empty room (ie: 0 occupants)
    return 'no-empty-rooms'
  }
  if (rooms.some(r => r.kids && r.kids.includes(-1))) {
    // All kids ages are required
    return 'kids-ages'
  }
  return false
}
