/**
 * Builds a throttled version of a function
 *
 * The provided function will be called at most every *timeout* ms, with the
 * most recent values provided.
 *
 * @param {function} fun The function to throttle
 * @param {number} timeout The number of ms between calls
 * @return A throttled version of *fun*
 */
const throttle = (fun, timeout) => {
  let lastCall = null
  let lastValue = null
  let timer = null

  const handleTimer = () => {
    timer = null
    ret.hasQueue = false
    lastCall = Date.now()
    fun(lastValue)
    lastValue = null
  }

  const ret = value => {
    const now = Date.now()
    const timeToNext = lastCall && lastCall + timeout - now
    if (timeToNext > 0 || timer) {
      // We're being throttled
      lastValue = value
      ret.hasQueue = true
      if (!timer) timer = setTimeout(handleTimer, timeToNext)
    } else {
      // We can make the call right now
      lastCall = now
      fun(value)
    }
  }

  ret.jumpQueue = value => {
    if (timer) clearTimeout(timer)
    timer = null
    ret.hasQueue = false
    lastCall = Date.now()
    fun(value)
    lastValue = null
  }

  ret.hasQueue = false

  return ret
}

export default throttle
