import intl from './reducers/intl'
import display from './reducers/display'
import selection from './reducers/selection'
import culture from './reducers/culture'
import api from './reducers/api'

export const reducerList = {
  // Add your reducers here
  intl,
  display,
  api,
  culture,
  selection
}

export default reducerList
