import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Search from './Search/Search'
import DatesSection from './Calendar/DatesSection'
import Code from './Code/Code'
import RoomType from './RoomType/RoomType'
import { ManualModalWrapper, Modal } from '@gluedigital/modal'
import SearchButton from './SearchButton'
import { hideDisplay, showDisplay } from '../store/actions/display'

import './Widget.sass'
import './Themes.sass'


const Widget = ({ display, hideDisplay, theme }) => {

  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = useCallback(
    () => {
      setOpenModal(!openModal)
    },
    [openModal]
  )

  const classNames = ['widget']
  if (display.type) classNames.push('display-' + display.type)
  if (openModal) classNames.push('modal-active')
  if (theme.themeType === 1 || theme.themeType === 3) {
    classNames.push('light-theme')
  } else {
    classNames.push('dark-theme')
  }
  if (theme.themeType === 2 || theme.themeType === 3) {
    classNames.push('vertical')
  } else {
    classNames.push('horizontal')
  }

  const ctx = {
    show: (modal, options) => showDisplay(modal, true, options),
    hide: hideDisplay,
    modal: display.isModal && display.type,
    options: display.opts
  }

  const primaryColor = theme && theme.colors && theme.colors.primary
  const primaryColorToAlpha = () => {
    let r = 0, g = 0, b = 0
    const h = primaryColor
    // 3 digits
    if (h.length === 4) {
      r = `0x${h[1]}${h[1]}`
      g = `0x${h[2]}${h[2]}`
      b = `0x${h[3]}${h[3]}`

    // 6 digits
    } else if (h.length === 7) {
      r = `0x${h[1]}${h[2]}`
      g = `0x${h[3]}${h[4]}`
      b = `0x${h[5]}${h[6]}`
    }
    return `rgba(${+r},${+g},${+b}, 0.2)`;
  }

  const style = theme && theme.colors && `
    .widget {
      --primary-color: ${theme.colors.primary};
      --secondary-color: ${theme.colors.secondary};
      --primary-color-alpha: ${primaryColorToAlpha()};
    }
  `
  return (
    <ManualModalWrapper ctx={ctx} >
      <button id="solbooking-modal-button" onClick={handleOpenModal}>Reservar</button>
      <div className={classNames.join(' ')}>
        <style>{style}</style>
        <div className="widget-wrapper">
          <h3 className="general-title select-title only-responsive">
            <FormattedMessage id="widget.select-title" />
          </h3>
          <Search />
          <DatesSection />
          <RoomType options={['single', 'double', 'multiple']} />
          <Code />
          <SearchButton />
          <div className="modal-close" id="toogle-modal" onClick={handleOpenModal}>
            <FormattedMessage id="close" />
            <span className="sol-icon sol-icon-cancel" />
          </div>
        </div>
        <Modal>
          {(close) =>
            <div className="modal-close" onClick={close}>
              <span className="sol-icon sol-icon-angle-left" />
              <FormattedMessage id="back" />
            </div>
          }
        </Modal>
      </div>
    </ManualModalWrapper>
  )
}

Widget.propTypes = {
  display: PropTypes.object.isRequired,
  showDisplay: PropTypes.func.isRequired,
  hideDisplay: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  display: state.display,
  theme: state.api.config.theme || {}
})

const mapDispatchToProps = {
  showDisplay,
  hideDisplay
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget)
