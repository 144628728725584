/**
 * API calls reducer
 *
 * Contains the responses to any API call we make.
 * @module
 */

import { combineReducers } from 'redux'
import { reducer as calendarReducer } from '@solbooking/calendar'
import rollingCallsReducer from '../../util/rollingCallsReducer'

/**
 * Hotel list
 *
 * Example value:
 * ```json
 *   [
 *     {
 *       code: null,
 *       groups: [...groups...],
 *       hotels: null
 *     }, {
 *       code: 1,
 *       groups: [...groups...],
 *       hotels: null
 *     }, {
 *       code: 29,
 *       groups: null,
 *       hotels: [...hotels...]
 *     }
 *   ]
 * ```
 *
 * Used when viewing all hotels as a list (instead of searching).
 *
 * The list is presented on levels, where:
 *   - Level 0 is world, and we get a group for each country
 *   - Level 1 is country, and we get a group for each region
 *   - Level 2 is region, and we get all hotels on the region (no subgroups)
 *
 * Each level except the first is requested with a code of the entity selected
 * at the previous level. We never show two entities of the same level.
 *
 * The value is an array of the currently visible levels and entities.
 */
const listReducer = (state = [], action) => {
  if (action.type === 'getList/loading') {
    const { type, code } = action.meta
    const nextState = state.slice(0, type)
    nextState[type] = { code, isLoading: true }
    return nextState
  } else if (action.type === 'getList/success') {
    const { type, code } = action.meta
    const nextState = state.slice(0, type)
    nextState[type] = {
      code,
      groups: action.payload.groupGetList,
      hotels: action.payload.listSimpleHotelList,
    }
    return nextState
  } else {
    return state
  }
}

/**
 * Config from API key
 *
 * Simple reducer with just the API response.
 *
 * Not even loading state (gets called before showing anything).
 */
const configReducer = (state = null, action) => {
  switch (action.type) {
    case 'getConfig/success':
    case 'setConfig':
      return action.payload
    case 'getConfig/error':
      return { isError: true }
    default:
      return state
  }
}

/**
 * Related/nearby hotels
 *
 * Shown under calendar on some cases. Simple reducer with the raw response.
 *
 * We must clear them when the hotel changes.
 */
const nearbyReducer = (state = null, action) => {
  switch (action.type) {
    case 'getNearby/success':
      return action.payload
    case 'SET_HOTEL':
      return null
    default:
      return state
  }
}

/**
 * Autocomplete results
 *
 * This reducer has a somewhat complex behaviour keeping the previous results
 * while more recent ones are fetched, and discarding out-of-order responses
 * in case the API gets slow.
 *
 * The reusable logic for these kind of "rolling" calls was moved to
 * `rollingCallsReducer`, and here we just bind it to the autocomplete actions.
 *
 * @function
 * @see rollingCallsReducer
 */
const autocomplete = rollingCallsReducer('autocomplete')

const apiKeyReducer = (state = null, action) => {
  if (action.type === 'setApiKey') return action.payload
  return state
}

export default combineReducers({
  key: apiKeyReducer,
  config: configReducer,
  autocomplete,
  list: listReducer,
  calendar: calendarReducer,
  nearby: nearbyReducer
})
