import networkAction from '@gluedigital/network-action'
import stateToQuery from '../../util/query'
import { actions } from '@solbooking/calendar'

const apiAction = (opts) => (dispatch, getState) => {
  const state = getState()
  const apiKey = state.api.key
  const { locale, currency } = state.intl
  const mixedOpts = {
    baseUrl: process.env.REACT_APP_API_URL || '/',
    processResponse: (val) => {
      if (val.code >= 400) throw new Error(val.message)
      return val.data
    },
    noReject: true,
    ...opts,
    fetchOptions: {
      headers: {
        'X-User-Currency': currency,
        'X-User-Locale': locale
      },
      ...opts.fetchOptions
    },
    body: { ...opts.body, apiKey }
  }

  return dispatch(networkAction(mixedOpts))
}

export const setApiKey = (key) => ({ type: 'setApiKey', payload: key })

export const getConfig = () => apiAction({
  action: 'getConfig',
  url: 'Widget/ValidateAPIKey',
  noReject: false,
  body: {}
})

export const setConfig = (conf) => ({
  type: 'setConfig',
  payload: conf
})

export const autocomplete = criteria => (dispatch, getState) => {
  if (!criteria || criteria.length < 3) return { type: 'autocomplete/clear' }
  const ac = getState().api.autocomplete
  if (ac.meta && ac.meta.criteria === criteria) return // Don't repeat the same query
  return dispatch(apiAction({
    action: 'autocomplete',
    url: 'Widget/Autocomplete',
    body: { criteria },
    meta: { criteria }
  }))
}

export const getCalendar = actions.getCalendar(apiAction)

export const getCalendarBetween = (dates) => (dispatch, getState) => {
  // Loop between the dates getting all relevant availabilities
  const start = new Date(dates[0])
  const end = new Date(dates[1])
  let d = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), 1))
  while (d <= end) {
    const month = d.getUTCMonth() + 1
    const key = d.getUTCFullYear() + '-' + (month < 10 ? '0' + month : month)
    dispatch(getCalendar(key))
    d.setUTCMonth(month)
  }
}

export const getList = (type, code) => apiAction({
  action: 'getList',
  url: 'Widget/GetList',
  meta: { type, code },
  body: { type, code: code || '' }
})

export const getNearby = () => (dispatch, getState) => {
  return dispatch(apiAction({
    action: 'getNearby',
    url: 'Widget/GetNearbyHotels',
    body: stateToQuery(getState())
  }))
}
