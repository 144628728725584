import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hideDisplay, showDisplay } from '../../store/actions/display'
import { FormattedMessage, FormattedDate } from 'react-intl'

import './CalendarModal'
import './Calendar.sass'

const DateModule = ({ name, selectedDate }) => {
  const emptyDate = <FormattedMessage id={'calendar.' + name} />
  const fullDate =
    <div className="full-date">
      <div className="day">
        <FormattedDate
          value={new Date(selectedDate)}
          day="2-digit"
        />
      </div>
      <div className="wrapper">
        <div className="month">
          <FormattedDate
            value={new Date(selectedDate)}
            month="short"
          />
        </div>
        <div className="year">
          <FormattedDate
            value={new Date(selectedDate)}
            year="2-digit"
          />
        </div>
      </div>
    </div>

  return(
    <div className={'date ' + name}>
      <span className="sol-icon sol-icon-calendar" />
      {selectedDate ? fullDate : emptyDate}
    </div>
  )
}

DateModule.propTypes = {
  name: PropTypes.string.isRequired,
  selectedDate: PropTypes.object
}

const Calendar = ({ active, setActive, setInactive, selectedDates }) => {

  selectedDates = selectedDates && selectedDates.map(d => new Date(d))
  let classList = 'widget-component'
  if (active) {
    classList += ' active'
  }
  if (selectedDates[0] && !selectedDates[1]) {
    classList += ' pick-out'
  }

  const toggleActive = () => {
    if (active) {
      setInactive()
    } else {
      setActive()
    }
  }

  return (
    <div className={classList} id="solbooking-widget-calendar"
      onClick={toggleActive}>
      <DateModule selectedDate={selectedDates[0]} name="check-in" />
      <DateModule selectedDate={selectedDates[1]} name="check-out" />
    </div>
  )
}

Calendar.propTypes = {
  setActive: PropTypes.func.isRequired,
  setInactive: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  selectedDates: PropTypes.array
}

const mapStateToProps = (state) => ({
  active: state.display.type === 'calendar',
  selectedDates: state.selection.dates || []
})

const mapDispatchToProps = {
  setActive: () => showDisplay('calendar', true),
  setInactive: hideDisplay
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
