import React from 'react'
import PropTypes from 'prop-types'
import { Provider as ReduxProvider, useSelector } from 'react-redux'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

const IntlProvider = ({ children }) => {
  const intl = useSelector(s => s.intl)
  return (
    <ReactIntlProvider key={intl.locale} {...intl}>
      {children}
    </ReactIntlProvider>
  )
}

IntlProvider.propTypes = {
  children: PropTypes.node
}

const Provider = ({ store, children }) =>
  <ReduxProvider store={store}>
    <IntlProvider>
      {children}
    </IntlProvider>
  </ReduxProvider>

Provider.propTypes = {
  store: PropTypes.object.isRequired,
  children: PropTypes.node
}

export default Provider
