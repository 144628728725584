import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hideDisplay, showDisplay } from '../../store/actions/display'
import { setCode, deleteCode } from '../../store/actions/selection'
import { FormattedMessage } from 'react-intl'

import './Code.sass'

const Code = ({ opts, active, setActive, setInactive, setCode, code }) => {

  let classToggleActive = 'widget-component'
  if (active) {
    classToggleActive += ' active'
  }

  const allowCodes = opts && opts.components && opts.components.aceptCoupons
  if (!allowCodes) return false

  return (
    <div className={classToggleActive} id="solbooking-widget-code">
      <label>
        <span className="sol-icon sol-icon-tag" />
        <FormattedMessage id="code.placeholder">
          {(text) =>
            <input
              type="text"
              name="code"
              placeholder={text}
              value={code}
              onChange={e => setCode(e.target.value)}
              onClick={setActive}
              onBlur={setInactive}
            />
          }
        </FormattedMessage>
      </label>
    </div>
  )
}

Code.propTypes = {
  opts: PropTypes.object.isRequired,
  setActive: PropTypes.func.isRequired,
  setInactive: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  code: PropTypes.string
}

const mapStateToProps = (state) => ({
  opts: state.api.config,
  active: state.display.type === 'code',
  code: state.selection.code || ''
})

const mapDispatchToProps = {
  setActive: () => showDisplay('code'),
  setInactive: hideDisplay,
  setCode,
  deleteCode
}

export default connect(mapStateToProps, mapDispatchToProps)(Code)
