/**
 * Converts a selection into URL params for transfer to SolEngine
 *
 * The returned value will be a packed string such as:
 * ```
 *   k=12345678-1234-1234-1234-123456789abc&q=24&qt=h&d=20190903-20190905&r=1&c=DESC1
 * ```
 *
 * Where:
 *   - k: api key
 *   - q: search selection id (eg: selected hotel's id)
 *   - qt: search selection type (h=hotel, r=region, t=theme)
 *   - d: dates (start-end, in yyyymmdd format)
 *   - r: rooms (comma-separated rooms, each as adultNumber-kidAge1-kidAge2...)
 *   - c: promo code
 *
 * @param {object} sel The current selection which will be serialized
 * @param {object} api The api info available (needed to get the api key)
 * @return A query string with the provided selection
 */

const selectionToParams = (sel, api, intl) => {
  let parts = []

  // Api key
  parts.push('k=' + api.key)

  // Query
  if (sel.hotel.type === 'hotel') {
    parts.push('q=' + sel.hotel.hotel.code)
    parts.push('qt=h')
  } else if (sel.hotel.type === 'region') {
    parts.push('q=' + sel.hotel.region)
    parts.push('qt=r')
  } else if (sel.hotel.type === 'theme') {
    parts.push('q=' + sel.hotel.theme)
    parts.push('qt=t')
  }

  // Dates
  const date2str = s => {
    const t = new Date(s)
    let ret = '' + t.getUTCFullYear()
    const m = t.getUTCMonth() + 1
    const d = t.getUTCDate()
    ret += m < 10 ? '0' + m : m
    ret += d < 10 ? '0' + d : d
    return ret
  }
  parts.push('d=' + date2str(sel.dates[0]) + '-' + date2str(sel.dates[1]))

  // Rooms
  let rooms = sel.rooms.map(r => [r.adults || 0, ...(r.kids || [])].join('-'))
  parts.push('r=' + rooms.join(','))

  // Code
  sel.code && parts.push('c=' + sel.code)

  // Intl
  parts.push('l=' + intl.locale)
  parts.push('m=' + intl.currency)

  // Join it all together
  return parts.join('&')
}

export default selectionToParams
