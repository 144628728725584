import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './CardPagination.sass'

const CardPagination = ({ list, groupLength = 1, children }) => {

  const [initialItem, setInitialItem] = useState(0)
  const [finalItem, setfinalItem] = useState(groupLength)

  if (!list) return false

  const handleNext = () => {
    if (finalItem < list.length) {
      setInitialItem(initialItem + groupLength)
      setfinalItem(finalItem + groupLength)
    }
  }

  const handlePrev = () => {
    if (initialItem >= groupLength) {
      setInitialItem(initialItem - groupLength)
      setfinalItem(finalItem - groupLength)
    } else {
      setInitialItem(0)
      setfinalItem(groupLength)
    }
  }

  let nextIcon = 'sol-icon sol-icon-angle-right next'
  let prevIcon = 'sol-icon sol-icon-angle-left prev'
  if (initialItem === 0) {
    prevIcon += ' disabled'
  }
  if (initialItem >= list.length - groupLength) {
    nextIcon += ' disabled'
  }

  return (
    <div className="card-pagination">
      <span className={prevIcon} onClick={handlePrev} />
      <div className={'slider-content group-' + groupLength}>
        {list.slice(initialItem, finalItem).map((item, i) =>
          children(item, { index: i + initialItem, total: list.length })
        )}
      </div>
      <span className={nextIcon} onClick={handleNext} />
    </div>
  )
}

CardPagination.propTypes = {
  list: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  groupLength: PropTypes.number
}

export default CardPagination
