/**
 * Display reducer
 *
 * Keeps track of the current modal or drop-down being shown, with its settings.
 *
 * It gets integrated with `@gluedigital/modal` via `ManualModalWrapper` at
 * `Widget.js`.
 *
 * @module
 */
export const display = (state =  {}, action) => {
  switch (action.type) {
    case 'DISPLAY_SHOW':
      return { ...state, type: action.display, isModal: action.isModal, opts: action.opts}
    case 'DISPLAY_HIDE':
      return {}
    default:
      return state
  }
}

export default display
