import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { registerModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { addRoom, removeRoom} from '../../store/actions/selection'
import { hideDisplay } from '../../store/actions/display'
import NumberSelector from './NumberSelector'
import RoomCard from './RoomCard'
import { validateRooms } from '../../util/validation'

import './MultipleModal.sass'

export const MultipleModal = ({ addRoom, removeRoom, rooms, maxRooms, showErrors, hideDisplay }) => {

  const currentError = validateRooms(rooms)
  const [everValid, setEverValid] = useState(!currentError)
  if (!everValid && !currentError) setEverValid(true)

  const minRoomAmount = 1

  const handleRooms = (value) => {
    if (value > rooms.length) {
      addRoom()
    } else {
      removeRoom(value)
    }
  }
  let addRoomButton = ''

  if (rooms.length < maxRooms) {
    addRoomButton =
      <div className="add-room" onClick={() => handleRooms((rooms.length + 1))}>
        <span className="sol-icon sol-icon-plus" />
        <FormattedMessage id="multiple-modal.add-room" />
      </div>
  }
  return (
    <div className="modal-content multiple-modal">
      {(!everValid || (showErrors && currentError)) && (
        <div className="error error-bar">
          <FormattedMessage id={'multiple-modal.error.' + currentError} />
        </div>
      )}
      <div className="room-amount">
        <h2>
          <FormattedMessage id="multiple-modal.room-amount" />
        </h2>
        <NumberSelector min={minRoomAmount} max={maxRooms} value={rooms.length} onChange={handleRooms} />
      </div>
      <div className="room-card-list">
        {rooms.map((k, i) =>
          <RoomCard
            roomIdx={i}
            key={i}
            canDeleted={rooms.length > 1}
            deleteCard={removeRoom}
          />)
        }
        {addRoomButton}
        <button className="accept only-vertical" onClick={hideDisplay}>
          <FormattedMessage id="accept" />
        </button>
      </div>

    </div>
  )
}

RoomCard.propTypes = {
  rooms: PropTypes.array,
  maxRooms: PropTypes.number
}

const mapStateToProps = (state) => ({
  rooms: state.selection.rooms || [],
  maxRooms: state.api.config.components && state.api.config.components.maxRooms
})

const mapDispatchToProps = {
  removeRoom,
  addRoom,
  hideDisplay
}

registerModal('room-type-multiple', connect(mapStateToProps, mapDispatchToProps)(MultipleModal))
