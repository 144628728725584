import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hideDisplay, showDisplay } from '../../store/actions/display'
import { setSingleRoom, setDoubleRoom } from '../../store/actions/selection'
import { FormattedMessage } from 'react-intl'

import './MultipleModal'
import './RoomType.sass'

const RoomType = ({ options, active, setActive, setInactive, setActiveMultiple, setDoubleRoom, setSingleRoom, rooms }) => {

  let classToggleActive = 'widget-component'
  if (active) {
    classToggleActive += ' active'
  }

  let optionState = 'multiple'
  if (rooms && rooms.length === 1 && (!rooms[0].kids || rooms[0].kids.length === 0)) {
    if (rooms[0].adults === 1) {
      optionState = 'single'
    } else if (rooms[0].adults === 2) {
      optionState = 'double'
    }
  }

  const handleSelect = () => {
    if (active) {
      // Close if clicked when open
      setInactive()
    } else {
      // Open with click instead of focus, because otherwise re-opening while it still
      // had focus (ie: closed from setInactive) would not work
      setActive()
    }
  }

  const handleOption = (option) => e => {
    if (option === 'multiple') {
      setActiveMultiple()
    } else if (option === 'single') {
      setSingleRoom()
      setInactive()
    } else if (option === 'double') {
      setDoubleRoom()
      setInactive()
    } else {
      setInactive()
    }
  }

  const handleOptions = e => {
    // We need to prevent the onBlur, as it will run after the handleOption handling,
    // potentially closing the just-opened modal.
    e.preventDefault()
  }

  const handleBlur = e => {
    if (active) setInactive()
  }

  return (
    <div className={classToggleActive} id="solbooking-widget-room_type">
      <div className="select-wrapper">
        <div className="selected-option" tabIndex="0" onClick={handleSelect} onBlur={handleBlur} >
          <span className={'sol-icon sol-icon-' + (optionState === 'single' ? 'user' : 'users')} />
          <input type="hidden" value={optionState} />
          <FormattedMessage id={'room-type.' + optionState} />
          <span className="sol-icon sol-icon-angle-down" />
        </div>
        <ul className="options" onMouseDown={handleOptions}>
          {options.map((option, i) => {
            return (
              <li key={i} className="option" onMouseDown={handleOption(option)}>
                <span className={'sol-icon sol-icon-' + (option === 'single' ? 'user' : 'users')} />
                <FormattedMessage id={'room-type.' + option} />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

RoomType.propTypes = {
  options: PropTypes.array.isRequired,
  rooms: PropTypes.array.isRequired,
  setActive: PropTypes.func.isRequired,
  setInactive: PropTypes.func.isRequired,
  setActiveMultiple: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  active: state.display.type === 'room-type',
  rooms: state.selection.rooms
})

const mapDispatchToProps = {
  setActive: () => showDisplay('room-type'),
  setInactive: hideDisplay,
  setActiveMultiple: () => showDisplay('room-type-multiple', true),
  setDoubleRoom,
  setSingleRoom
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomType)
