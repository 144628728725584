import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showDisplay } from '../../store/actions/display'
import { setSelection } from '../../store/actions/selection'
import { FormattedMessage, FormattedDate } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { hotelFilter } from '../../util/filters'

import './ListModal'
import './MapModal'
import './SearchPopup.sass'

let history = []

const SearchPopup = ({ query, queued, results, showMap, onPick, showList, features, setSelection, staticList }) => {
  // On mount, load search history from localStorage to a shared var
  useEffect(() => {
    history = JSON.parse(localStorage.getItem('sol-widget-history') || '[]')
  }, [])

  let historyArea = false
  if (features.aceptHistory && history.length) {
    historyArea =
      <li className="history-results">
        <h3><FormattedMessage id="search.history" /></h3>
        <ul className="history-options">
          {history.map((h, i) => (
            <li key={i} className="option" onClick={() => setSelection(h)}>
              <div className="sol-icon sol-icon-back-in-time" />
              <div className="option-wrapper">
                <div className="hotel">{h.hotel.label}</div>
                <div className="dates">
                  <FormattedDate value={h.dates[0]} day="numeric" month="short" />
                  -
                  <FormattedDate value={h.dates[1]} day="numeric" month="short" />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </li>
  }

  const val = results && results.value

  let hotelsArea, destinationsArea, thematicArea
  let hasResults = false

  const type = features.listType

  if (type === 1 && val && val.hotels && val.hotels.listGroup.length) {
    hasResults = true
    const hotelsResults = val.hotels.listGroup.map((hotel, i) => {
      const handler = () => onPick({ type: 'hotel', hotel, label: hotel.name })
      return (
        <li key={i} className="option" onClick={handler}>
          {hotel.name}
        </li>
      )
    })
    hotelsArea =
      <li className="hotels-results">
        <h3><FormattedMessage id="search.hotels" /></h3>
        <ul>
          {hotelsResults}
        </ul>
      </li>
  }

  if (type === 2 && staticList.length) {
    hasResults = true
    const hotelsResults = staticList.filter(hotelFilter(query.label)).map((hotel, i) => {
      const handler = () => onPick({ type: 'hotel', hotel, label: hotel.name })
      return (
        <li key={i} className="option" onClick={handler}>
          {hotel.name}
        </li>
      )
    })
    hotelsArea =
      <li className="hotels-results">
        <h3><FormattedMessage id="search.hotels" /></h3>
        <ul>
          {hotelsResults}
        </ul>
      </li>
  }

  if (val && val.destination && val.destination.listGroup.length) {
    hasResults = true
    const regions = val.destination.listGroup.reduce((regions, hotel) => {
      const { region, regionCode } = hotel.address
      regions[regionCode] = regions[regionCode] || { region, regionCode, count: 0 }
      regions[regionCode].count++
      return regions
    }, {})
    const destinationsResults = Object.keys(regions).map(k => {
      const { region, regionCode, count } = regions[k]
      const handler = () => onPick({ type: 'region', region: regionCode, label: region })
      return (
        <li key={regionCode} className="option" onClick={handler}>
          <MarkdownMessage id="search.destination" values={{destination: region, n: count}} />
        </li>
      )
    })
    destinationsArea =
      <li className="destinations-results">
        <h3><FormattedMessage id="search.destinations" /></h3>
        <ul>
          {destinationsResults}
        </ul>
      </li>
  }

  if (val && val.thematic && val.thematic.listGroup.length) {
    hasResults = true
    const thematicResults = val.thematic.listGroup.map((thematic, i) => {
      // TODO: This hasn't been tested: no results of this type yet
      const handler = () => onPick({ type: 'theme', theme: thematic , label: thematic.name })
      return (
        <li key={i} className="option" onClick={handler}>
          {thematic.name}
        </li>
      )
    })
    thematicArea =
      <li className="thematic-results">
        <h3><FormattedMessage id="search.thematic" /></h3>
        <ul>
          {thematicResults}
        </ul>
      </li>
  }

  let status = false
  if (!hasResults) {
    if (!query.label || query.label.length < 3) {
      status =
        <li className="status short">
          <FormattedMessage id="search.too-short" />
        </li>
    } else if ((results.isLoading || queued) && !results.value) {
      status =
        <li className="status loading">
          <FormattedMessage id="search.loading" />
        </li>
    } else {
      status =
        <li className="status no-results">
          <FormattedMessage id="search.no-results" />
        </li>
    }
  }

  const handleMap = evt => {
    evt.preventDefault() // Don't blur the autocomplete
    showMap()
  }

  const mapLink = !features.aceptMap ? '' :
    <span className="link map" onMouseDown={handleMap}>
      <FormattedMessage id="search.map" />
    </span>

  const listLink = !features.aceptList ? '' :
    <span className="link list" onClick={showList}>
      <FormattedMessage id="search.list" />
    </span>

  return (
    <ul className="options">
      {historyArea}
      {hotelsArea}
      {destinationsArea}
      {thematicArea}
      {status}
      <li className="links">
      {listLink}
      {mapLink}
      </li>
    </ul>
  )
}

SearchPopup.propTypes = {
  query: PropTypes.object.isRequired,
  queued: PropTypes.bool.isRequired,
  results: PropTypes.object.isRequired,
  showMap: PropTypes.func.isRequired,
  showList: PropTypes.func.isRequired,
  setSelection: PropTypes.func.isRequired,
  onPick: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
  staticList: PropTypes.array
}

const mapStateToProps = (state) => ({
  query: state.selection.hotel || {},
  results: state.api.autocomplete || {},
  components: state.api.autocomplete,
  features: state.api.config.components || {},
  staticList: state.api.config.simpleHotelListWidget
})

const mapDispatchToProps = {
  showMap: () => showDisplay('search-map', true),
  showList: () => showDisplay('search-list', true),
  setSelection
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPopup)
