import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { registerModal } from '@gluedigital/modal'
import Calendar from '@solbooking/calendar'
import CardPagination from './CardPagination/CardPagination'
import RelatedCard from './RelatedCard/RelatedCard'
import DatesSection from './DatesSection'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { setDates } from '../../store/actions/selection'
import { hideDisplay } from '../../store/actions/display'
import { getCalendar, getNearby } from '../../store/actions/api'
import { useIsMobile } from '../../util/responsive'

import './CalendarModal.sass'

export const CalendarModal = ({ getCalendar, setDates, hide, getNearby, nearby}) => {

  const isMobile = useIsMobile()
  const [currentError, setCurrentError] = useState(false)

  const onChange = (dates, error) => {
    currentError !== error && setCurrentError(error)
    if (error === 'not-available') {
      getNearby()
    }
    if (!error || error === 'no-info') {
      hide()
    }
  }

  const hotels = nearby.alternativeHotels

  let relatedHotels = false
  if (currentError && hotels && hotels.length > 0) {
    relatedHotels =
      <div className="related-hotels">
        <h3 className="related-title">
          <FormattedMessage id="related-title" />
        </h3>
        <CardPagination groupLength={isMobile ? 1 : 3}list={hotels}>
          {(info, { index }) =>
            <RelatedCard key={index} hotel={info} />
          }
        </CardPagination>
      </div>
  }

  return (
    <div className="modal-content calendar-modal">
      <h3 className="select-title only-vertical">
        <FormattedMessage id="calendar-modal.select-title" />
      </h3>
      <div className="dates-visualizator only-vertical">
        <div className="wrapper-dates-visualizator">
          <FormattedMessage id="calendar.check-in" />
          <FormattedMessage id="calendar.check-out" />
        </div>
        <DatesSection />
      </div>
      <Calendar onChange={onChange} getCalendar={getCalendar} setDates={setDates} />
      {relatedHotels}
    </div>
  )
}

CalendarModal.propTypes = {
  hotel: PropTypes.object,
  nearby: PropTypes.object.isRequired,
  getCalendar: PropTypes.func.isRequired,
  getNearby: PropTypes.func.isRequired,
  setDates: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired
}

const mapStateToProps = s => ({
  opts: s.api.config,
  dates: s.selection.dates,
  prices: s.api.calendar,
  hotel: s.selection.hotel.hotel,
  nearby: s.api.nearby || {}
})

const mapDispatchToProps = {
  getCalendar,
  getNearby,
  setDates,
  hide: hideDisplay
}

registerModal('calendar', connect(mapStateToProps, mapDispatchToProps)(CalendarModal))
