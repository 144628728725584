import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { validation } from '@solbooking/calendar'
import { hideDisplay, showDisplay } from '../store/actions/display'
import { validateRooms } from '../util/validation'
import selectionToParams from '../util/selectionToParams'

const useDateValidation = validation.useDateValidation

const SearchButton = ({ showDisplay, hideDisplay, selection, api, intl }) => {
  const datesValid = !useDateValidation()

  const handleClick = (e) => {
    e.preventDefault()
    debugger;
    // --- Form validation ---

    // Autocomplete:
    if (!selection.hotel.type || selection.hotel.type === 'input') {
      document.querySelector('#solbooking-widget-search input').focus()
      return
    }

    // Dates
    if (!datesValid) {
      showDisplay('calendar', true)
      return
    }

    // Rooms
    if (validateRooms(selection.rooms)) {
      showDisplay('room-type-multiple', true, { showErrors: true })
      return
    }

    // --- Save search ---
    let history = JSON.parse(localStorage.getItem('sol-widget-history') || '[]')
    history.unshift(selection)
    history = history.slice(0, 2) // Most recent 2
    localStorage.setItem('sol-widget-history', JSON.stringify(history))

    // --- Navigate away ---
    const base = api.config.baseURLBookingEngine
    const url = base + '?' + selectionToParams(selection, api, intl)
    window.location = url
  }

  return (
    <button className="submit" onClick={handleClick}>
      <FormattedMessage id="widget.search" />
    </button>
  )
}

SearchButton.propTypes = {
  showDisplay: PropTypes.func.isRequired,
  hideDisplay: PropTypes.func.isRequired,
  selection: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  selection: state.selection,
  api: state.api,
  intl: state.intl
})

const mapDispatchToProps = {
  showDisplay,
  hideDisplay
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchButton)
