/**
 * User selection reducer
 *
 * Contains fields for each part of the user search: autocomplete, dates, rooms,
 * promo code.
 * @module
 */

import { combineReducers } from 'redux'

// --- Hotel/Region selection ---

/**
 * Currently selected hotel/region/... on the autocomplete
 *
 * Example values:
 * ```json
 *   { type: 'input', label: 'alm' }
 *   { type: 'hotel', label: 'Playasol SPA', hotel: { code: 5, ...hotelinfo...} }
 *   { type: 'region', label: 'Almería', region: "54" }
 * ```
 *
 * This reducer can contain:
 *   - Some user input, without picking anything yet
 *   - A hotel, region, or thematic, picked from the results
 *
 * We have some special logic to make that if the user input matches exactly
 * the name of a hotel/destination, it gets auto-selected.
 */
const hotel = (state = {}, action) => {
  switch (action.type) {
    case 'SET_HOTEL':
      return action.hotel
    case 'autocomplete/success':
      if (state.type === 'input') {
        return acMatchInput(state.label, action.payload) || state
      }
      return state
    default:
      return state
  }
}

const acMatchInput = (label, payload) => {
  if (payload.hotels && payload.hotels.listGroup) {
    const hotel = payload.hotels.listGroup.find(v => v.name === label)
    if (hotel) return { type: 'hotel', label, hotel }
  }
  if (payload.destination && payload.destination.listGroup) {
    const hotel = payload.hotels.listGroup.find(v => v.address.region === label)
    if (hotel) return { type: 'region', label, region: hotel.address.region }
  }
}

// --- Dates selection ---

const atMidnight = (d) => {
  d.setUTCHours(0)
  d.setUTCMinutes(0)
  d.setUTCSeconds(0)
  d.setUTCMilliseconds(0)
}

/**
 * Currently selected dates
 *
 * Example value:
 * ```json
 *   ["2019-09-11T00:00:00.000Z", "2019-09-15T00:00:00.000Z"]
 * ```
 *
 * Note: Values can be either Date objects or strings. Code defensively.
 *
 * Apart from the simple case of the user picking some dates, we need to
 * set the default value when loading the config, if it contains one.
 *
 * The default value is set with some complex rules specifying the starting day,
 * plus a default stay length.
 */
const dates = (state = null, action) => {
  switch (action.type) {
    case 'SET_DATES':
      return action.dates
    case 'getConfig/success':
      const co = action.payload.calendarOptions
      if (co) {
        // Create a default start date, set to tomorrow midnight
        let targetDay = new Date()
        atMidnight(targetDay)

        // Update the date as needed
        const type = co.defaultCheckInType
        if (type === 0) {
          // Today; no action needed
        } else if (type === 1) {
          // Tomorrow; add one day
          targetDay.setUTCDate(targetDay.getUTCDate() + 1)
        } else if (type === 9) {
          // Specific date; set it directly and reset to midnight utc
          targetDay = new Date(co.defaultCheckInDate.split("T")[0] + 'Z')
          atMidnight(targetDay)
        } else if (type > 1 && type < 9) {
          // Day-of-week (sunday=2, monday=3, ..., saturday=8)
          // We must pay attention to the "release", adding a full week if needed
          let delta = type - 2 - targetDay.getUTCDay()
          if (delta < 0) delta += 7
          while (delta < co.release) delta += 7
          targetDay.setUTCDate(targetDay.getUTCDate() + delta)
        }

        // Now that we have the correct start date, calc the end date
        const endDay = new Date(targetDay)
        endDay.setUTCDate(endDay.getUTCDate() + co.defaultStay)

        return [targetDay, endDay]
      }
      return state
    default:
      return state
  }
}

// --- Rooms selection ---

const setAt = (list, idx, f) => {
  const listCopy = list.slice()
  listCopy[idx] = f(list[idx], idx)
  return listCopy
}
const removeAt = (list, idx) => {
  const listCopy = list.slice()
  listCopy.splice(idx, 1)
  return listCopy
}

/**
 * Rooms and occupants currently specified
 *
 * Example value:
 * ```json
 *   [{ adults: 2, kids: [4]}]
 * ```
 *
 * The value is an array of rooms, each containing a adults count, and a list
 * with the ages of each kid (the number of kids is this array's length).
 *
 * As adding/removing elements to this structure can be hard, we have lots of
 * actions to add/remove kids and rooms, and some helper functions.
 */
const rooms = (state = [{ adults: 1 }], action) => {
  switch (action.type) {
    case 'ADD_ROOM':
      return [...state, { adults: 1 } ]
    case 'REMOVE_ROOM':
      return removeAt(state, action.roomIdx)
    case 'SET_SINGLE_ROOM':
      return [{ adults: 1 }]
    case 'SET_DOUBLE_ROOM':
      return [{ adults: 2 }]
    case 'SET_ADULTS_AMOUNT':
      return setAt(state, action.roomIdx, room => ({ ...room, adults: action.amount }))
    case 'ADD_KID':
      return setAt(state, action.roomIdx, room => ({ ...room, kids: [...(room.kids || []), -1] }))
    case 'REMOVE_KID':
      return setAt(state, action.roomIdx, room => ({ ...room, kids: removeAt(room.kids, action.kidIdx) }))
    case 'SET_KID_AGE':
      return setAt(state, action.roomIdx, room => ({ ...room, kids: setAt(room.kids, action.kidIdx, () => action.age) }))
    default:
      return state
  }
}

// --- Promo code selection ---

/**
 * Currently set promo code
 *
 * Simple action to set it when editting, and default from config at load time.
 */
const code = (state = null, action) => {
  switch (action.type) {
    case 'SET_CODE':
      return action.code
    case 'DELETE_CODE':
      return null
    case 'getConfig/success':
      if (action.payload && action.payload.defaultPromotionCode) {
        return action.payload.defaultPromotionCode.code
      }
      return state
    default:
      return state
  }
}

// --- Global selection ---

const selectionReducer = combineReducers({
  hotel,
  dates,
  rooms,
  code
})

const selectionReducerWithOverride = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTION':
      return action.selection
    default:
      return selectionReducer(state, action)
  }
}

export default selectionReducerWithOverride
