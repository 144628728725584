import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hideDisplay, showDisplay } from '../../store/actions/display'
import { setHotel } from '../../store/actions/selection'
import { getList } from '../../store/actions/api'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { registerModal } from '@gluedigital/modal'

import './ListModal.sass'

export const ListModal = ({ getList, results, showMap, hideDisplay, setHotel, aceptMap }) => {

  useEffect(() => {
    getList(0, null)
  }, [getList])

  const [countryState, setCountryState] = useState()
  const [regionState, setRegionState] = useState()

  const setGroup = (type, code, value) => {
    getList(type, code)
    if (type === 1) {
      setCountryState({ code: code, label: value })
    } else {
      setRegionState({ code: code, label: value })
    }
  }

  const pickDestination = (destination) => {
    setHotel({ type: 'region', region: destination.code, label: destination.label })
    hideDisplay()
  }

  const pickHotel = (hotel) => {
    setHotel({ type: 'hotel', hotel, label: hotel.name })
    hideDisplay()
  }

  const val = results
  if (!val) return false

  const ids = ['countries', 'regions', 'hotels']

  const pages = val.map((entry, i) => {
    const hotelOptions =
      <React.Fragment>
        <h4><FormattedMessage id="search.list.destination" /></h4>
        <li className="option" onClick={() => pickDestination(regionState)}>
          <FormattedMessage id="search.list.view-hotels-in"
            values={{destination: regionState && regionState.label}} />
        </li>
        <h4>
          <FormattedMessage id="search.list.hotels-in"
            values={{destination: regionState && regionState.label}} />
        </h4>
      </React.Fragment>
    return (
      <ul key={i} id={ids[i]} className="options">
        {entry.isLoading && <li className="option">Cargando...</li>}
        {ids[i] === 'hotels' && !entry.isLoading ? hotelOptions : null}
        {entry.groups && entry.groups.map(group => {
          const groupState = ids[i] === 'countries' ? countryState : regionState
          const isActive = groupState && groupState.label === group.identifyingText
          const activeClass = isActive ? 'active option' : 'option'
          return (
            <li className={activeClass} key={group.identifyingCode}
              onClick={() => setGroup(i + 1, group.identifyingCode, group.identifyingText)}
            >
              <MarkdownMessage id="search.destination" values={{destination: group.identifyingText, n: group.hotelsCount}} />
              <span className="sol-icon sol-icon-angle-right" />
            </li>
          )
        })}
        {entry.hotels && entry.hotels.map(hotel => {
          return (
            <li key={hotel.code} className="option" onClick={() => pickHotel(hotel)}>
              <span className="hotel-name">{hotel.name}</span>
              <span className="stars">
                {[...Array(hotel.category)].map((star, i) => <span key={i} className="star"> * </span>)}
              </span>
            </li>
          )
        })}
      </ul>
    )
  })

  const mapLink = !aceptMap ? '' :
    <div className="link-map" onClick={showMap}>
      <FormattedMessage id="search.map" />
    </div>

  return (
    <div className="modal-content list-modal">
      <h3 className="select-title only-vertical">
        <FormattedMessage id="list-modal.select-title" />
      </h3>
      {mapLink}
      <div className="content">
        <div className="pages">
          {pages}
        </div>
      </div>
    </div>
  )
}

ListModal.propTypes = {
  results: PropTypes.array.isRequired,
  getList: PropTypes.func.isRequired,
  showMap: PropTypes.func.isRequired,
  hideDisplay: PropTypes.func.isRequired,
  setHotel: PropTypes.func.isRequired,
  aceptMap: PropTypes.bool
}

const mapStateToProps = (state) => ({
  results: state.api.list || [],
  aceptMap: state.api.config.components && state.api.config.components.aceptMap
})

const mapDispatchToProps = {
  getList,
  showMap: () => showDisplay('search-map', true),
  hideDisplay,
  setHotel
}

registerModal('search-list', connect(mapStateToProps, mapDispatchToProps)(ListModal))
