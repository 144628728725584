import locales from '../../locales/index'
const initialState = { locale: 'es', messages: locales['es'], currency: 'EUR' }

const intlReducer = (state = initialState, action) => {
  if (action.type !== '@@intl/UPDATE') {
    return state
  }

  return { ...state, ...action.payload }
}

export default intlReducer
