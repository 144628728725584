export const showDisplay= (type, isModal, opts) => ({
  type: 'DISPLAY_SHOW',
  display: type,
  isModal,
  opts
})

export const hideDisplay = () => ({
  type: 'DISPLAY_HIDE'
})
