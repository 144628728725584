import { render, createStore, autoSetCulture, setCulture as actionSetCulture } from './util/render'
import { setApiKey, getConfig, setConfig } from './store/actions/api'
import { setSelection as actionSetSelection } from './store/actions/selection'

const store = createStore()

// When load is complete, trigger a DOM event
const triggerEvent = (name) => {
  const event = document.createEvent('Event')
  event.initEvent(name, true, true)
  document.dispatchEvent(event)
}
window.setTimeout(() => triggerEvent('solwidgetLoaded'), 0)

/**
 * Creates the root element for rendering, and waits until CSS is fully loaded.
 * This is needed to prevent a FOUC.
 */
const createElements = () => {
  // Find the current script
  const me = document.getElementById('solwidget-script')
  if (!me) {
    console.warn('Solbooking Widget: Script not found. Please check your snippet.')
    return
  }

  // Create the render wrapper div
  const target = document.createElement('div')
  target.setAttribute('id', 'solbooking-widget')
  me.parentNode.insertBefore(target, me)

  // Add the stylesheet and make it trigger the render
  const link = document.createElement('link')
  link.setAttribute('rel', 'stylesheet')
  link.setAttribute('href', me.getAttribute('src').replace(/\.js$/, '.css'))
  const p = new Promise(resolve => {
    link.addEventListener('load', () => {
      resolve(target)
    })
  })
  document.head.appendChild(link)
  return p
}

/**
 * Initializes the widget - called from the code snippet
 */
export const init = async (key) => {
  // Prevent duplicated init
  if (document.getElementById('solbooking-widget')) {
    console.warn('Solbooking Widget: Double init detected. Please check your snippet.')
    return
  }

  store.dispatch(setApiKey(key))
  await store.dispatch(getConfig())
  autoSetCulture(store)

  const target = await createElements()
  render(store, target)
}

/**
 * Initializes the widget - used by Engine to provide the settings itself
 */
export const manualInit = async (target, key, config) => {
  store.dispatch(setApiKey(key))
  store.dispatch(setConfig(config))
  autoSetCulture(store)
  render(store, target)
}

/**
 * Updates the current selection with the provided one
 */
export const setSelection = (sel) => {
  store.dispatch(actionSetSelection(sel))
}

/**
 * Sets the culture for the widget and next steps
 */
export const setCulture = (culture, currency) => {
  actionSetCulture(store, culture, currency)
}
