import React from 'react'
import PropTypes from 'prop-types'
import NumberSelector from './NumberSelector'
import { connect } from 'react-redux'
import { setAdultsAmount, addKid, removeKid, setKidAge } from '../../store/actions/selection'
import { FormattedMessage } from 'react-intl'

import './RoomCard.sass'

const RoomCard = ({ roomIdx, canDeleted, addKid, removeKid, setAdultsAmount, setKidAge ,deleteCard, adults, kids, maxGuests }) => {

  const handleKids = (idx, value) => {
    if (value > kids.length) {
      addKid(idx)
    } else {
      removeKid(idx, value)
    }
  }

  return (
    <div className="multiple-room-card">
      {canDeleted ? <div className="sol-icon sol-icon-cancel" onClick={() => deleteCard(roomIdx)} /> : ''}
      <h3>
        <FormattedMessage id="multiple-room-card.name" values={{n: (roomIdx + 1)}} />
      </h3>
      <div className="wrapper">
        <div className="adults">
          <FormattedMessage id="multiple-room-card.adults" />
          <NumberSelector max={maxGuests} roomIdx={roomIdx} value={adults} onChange={n => setAdultsAmount(roomIdx, n)} />
        </div>
        <div className="kids">
          <FormattedMessage id="multiple-room-card.kids" />
          <NumberSelector max={maxGuests} value={kids.length} onChange={n => handleKids(roomIdx, n)} />
        </div>
        <div className="ages">
          {kids.length > 0 ? <FormattedMessage id="multiple-room-card.kids-ages" /> : ''}
          <div className="ages-wrapper">
            {kids.map((kid, i) => {
              return (
                <div key={i} className="select-wrapper">
                  <select value={kids[i]} onChange={e => setKidAge(roomIdx, i, +(e.target.value))} required>
                    <FormattedMessage id="multiple-room-card.kid" values={{n: (i + 1)}}>
                      {(text) => <option value={-1} disabled hidden>{text}</option>}
                    </FormattedMessage>
                    {[...Array(18)].map((age, j) => {
                      return (
                        <FormattedMessage key={j} id="multiple-room-card.kid-age" values={{ages: j}}>
                          {(text) => <option value={j}>{text}</option>}
                        </FormattedMessage>
                      )
                    })}
                  </select>
                  <span className="sol-icon sol-icon-angle-down" />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

RoomCard.propTypes = {
  roomIdx: PropTypes.number.isRequired,
  canDeleted: PropTypes.bool.isRequired,
  deleteCard: PropTypes.func.isRequired,
  adults: PropTypes.number,
  kids: PropTypes.array,
  addKid: PropTypes.func.isRequired,
  removeKid: PropTypes.func.isRequired,
  setAdultsAmount: PropTypes.func.isRequired,
  setKidAge: PropTypes.func.isRequired,
  maxGuests: PropTypes.number
}

const mapStateToProps = (state, ownProps) => ({
  adults: state.selection.rooms[ownProps.roomIdx].adults || 0,
  kids: state.selection.rooms[ownProps.roomIdx].kids || [],
  maxGuests: state.api.config.components && state.api.config.components.maxGuests
})

const mapDispatchToProps = {
  setAdultsAmount,
  removeKid,
  addKid,
  setKidAge
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomCard)
