import { autocomplete, getCalendarBetween } from './api'
import { actions as calendarActions } from '@solbooking/calendar'

// --- Hotel/Region selection ---

export const setHotel = (hotel) => (dispatch, getState) => {
  dispatch({ type: 'SET_HOTEL', hotel })
  // If we have dates already selected, we need to update availability now
  if (hotel.type === 'hotel') {
    const state = getState()
    const dates = state.selection.dates
    const isExtended = state.api.config.components.aceptExtendedCalendar
    if (dates && isExtended) {
      dispatch(getCalendarBetween(dates))
    }
  }
}

// --- Dates selection ---

export const setDates = calendarActions.setDates

// --- Rooms selection ---

export const addRoom = (roomIdx) => ({
  type: 'ADD_ROOM',
  roomIdx
})

export const removeRoom = (roomIdx) => ({
  type: 'REMOVE_ROOM',
  roomIdx
})

export const setSingleRoom = () => ({
  type: 'SET_SINGLE_ROOM',
})

export const setDoubleRoom = () => ({
  type: 'SET_DOUBLE_ROOM',
})

export const setAdultsAmount = (roomIdx, amount) => ({
  type: 'SET_ADULTS_AMOUNT',
  roomIdx,
  amount
})

export const addKid = (roomIdx) => ({
  type: 'ADD_KID',
  roomIdx
})

export const removeKid = (roomIdx, kidIdx) => ({
  type: 'REMOVE_KID',
  roomIdx,
  kidIdx
})

export const setKidAge = (roomIdx, kidIdx, age) => ({
  type: 'SET_KID_AGE',
  roomIdx,
  kidIdx,
  age
})

// --- Promo code selection ---

export const setCode = (code) => ({
  type: 'SET_CODE',
  code
})

export const deleteCode = () => ({
  type: 'DELETE_CODE'
})

// --- Global selection ---

export const setSelection = (selection) => (dispatch, getState) => {
  // Set the saved selection as the current state
  dispatch({ type: 'SET_SELECTION', selection })
  // Get availability & AC results
  dispatch(autocomplete(selection.hotel.label))
  dispatch(getCalendarBetween(selection.dates))
}
