import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setHotel } from '../../../store/actions/selection'

import './RelatedCard.sass'

const RelatedCard = ({ hotel, setHotel }) => {
  const url = hotel.hotelImages && hotel.hotelImages.length && hotel.hotelImages[0].mediumImageUrl
  const style = url ? { backgroundImage: 'url("' + url + '")' } : {}
  const relatedHotel = {
    name: hotel.hotelName,
    code: hotel.hotelCode
  }

  return (
    <div className="related-card">
      <div className="image" style={style} />
      <div className="card-content">
        <div className="hotel">
          <h2>
            {hotel.hotelName}
          </h2>
          <div id="stars">
            {[...Array(+hotel.category)].map((star, i) => <span key={i} className="star">*</span>)}
          </div>
        </div>
        <div className="location">
          {hotel.city}
        </div>
        <div className="select-hotel" onClick={() => setHotel({ type: 'hotel', hotel: relatedHotel, label: relatedHotel.name })}>
          <FormattedMessage id="select-hotel" />
        </div>
      </div>
    </div>
  )
}

RelatedCard.propTypes = {
  hotel: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  setHotel
}

export default connect(null, mapDispatchToProps)(RelatedCard)
