import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hideDisplay, showDisplay } from '../../store/actions/display'
import { autocomplete } from '../../store/actions/api'
import { setHotel } from '../../store/actions/selection'
import { FormattedMessage } from 'react-intl'
import throttle from '../../util/throttle'
import SearchPopup from './SearchPopup'

import './Search.sass'

const Search = ({ opts, displayType, setActive, setInactive, autocomplete, hotel, setHotel }) => {

  let classToggleExpand = 'widget-component'
  if (displayType === 'search' || displayType === 'search-map') {
    classToggleExpand += ' active'
  }

  const skipAC = opts.components && opts.components.listType !== 1

  const throttledAC = useRef(throttle(autocomplete, 1000))

  const handleChange = e => {
    const val = e.target.value || ''
    setHotel({ type: 'input', label: val })
    if (!skipAC) {
      // Don't waste throttle attempts on short strings that won't be requested
      val.length < 3 ? throttledAC.current.jumpQueue(val) : throttledAC.current(val)
    }
  }

  const handlePick = (value) => {
    setHotel(value)
    setInactive() // Hide popup
    !skipAC && throttledAC.current.jumpQueue(value.label) // In case we open the popup again
  }

  const type = opts && opts.components && opts.components.listType
  if (!type) return false // 0 - No autocomplete

  return (
    <div className={classToggleExpand} id="solbooking-widget-search">
      <label>
        <span className="sol-icon sol-icon-search" />
        <FormattedMessage id="search.placeholder">
          {(text) =>
            <input
              type="text"
              name="search"
              placeholder={text}
              onChange={handleChange}
              value={(hotel && hotel.label) || ''}
              autoComplete="off"
              onFocus={setActive}
              onBlur={setInactive}
            />
          }
        </FormattedMessage>
      </label>
      <SearchPopup onPick={handlePick} queued={throttledAC.current.hasQueue} />
    </div>
  )
}

Search.propTypes = {
  opts: PropTypes.object.isRequired,
  setActive: PropTypes.func.isRequired,
  setInactive: PropTypes.func.isRequired,
  displayType: PropTypes.string
}

const mapStateToProps = (state) => ({
  opts: state.api.config,
  displayType: state.display.type,
  hotel: state.selection.hotel
})

const mapDispatchToProps = {
  autocomplete,
  setHotel,
  setActive: () => showDisplay('search'),
  setInactive: hideDisplay
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
