/**
 * Creates a reducer for rolling calls
 *
 * The reducer keeps track of the previous value until a new one is received,
 * and ignores obsolete values received out-of-order.
 *
 * @param {string} actionType The name of the action to listen for
 * @return The reducer for this action
 */
const rollingCallsReducer = (actionType) => (state, action) => {
  if (action.type === actionType + '/clear') {
    return {
      responseTs: Date.now()
    }
  } else if (action.type === actionType + '/loading') {
    return {
      ...state,
      isLoading: true,
      requestTs: action.ts,
      meta: action.meta
    }
  } else if (action.type === actionType + '/success') {
    if (action.ts < state.responseTs) return state // Ignore out-of-order responses
    return {
      ...state,
      value: action.payload,
      responseTs: action.ts,
      meta: action.meta,
      isLoading: state.requestTs > action.ts,
      isError: false,
      isSuccess: true
    }
  } else if (action.type === actionType + '/error') {
    return {
      ...state,
      value: null,
      responseTs: action.ts,
      meta: action.meta,
      isLoading: state.requestTs > action.ts,
      isSuccess: false,
      isError: true
    }
  } else {
    return state || {}
  }
}

export default rollingCallsReducer
