const stateToQuery = (state) => {
  const q = { culture: state.api.config.defaultCulture }
  if (state.selection.dates && state.selection.dates.length === 2) {
    q.accommodationDateTime = {
      from: state.selection.dates[0],
      to: state.selection.dates[1]
    }
  }
  if (state.selection.rooms) {
    q.roomConfiguration = state.selection.rooms.map(r => ({
      adultsNumber: r.adults,
      childsNumber: (r.kids && r.kids.length) || 0,
      childsAges: r.kids || [],
      adultAges: []
    }))
  }
  if (state.selection.hotel && state.selection.hotel.hotel) {
    q.hotelCode = state.selection.hotel.hotel.code
  }
  return q
}

export default stateToQuery
