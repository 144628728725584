import React from 'react'
import ReactDOM from 'react-dom'
import { createStore as mkStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { reducerList } from '../store/reducers'
import { updateIntl } from '../store/actions/intl'
import Provider from '../components/Provider'
import { addLocaleData } from 'react-intl'
import esLocaleData from 'react-intl/locale-data/es'

import Widget from '../components/Widget'
import messages from '../locales'
import '../styles/main.sass'

// Add locale data for Spanish
addLocaleData(esLocaleData)

/**
 * Renders the root component (with its wrappers) on the root element.
 */
export const render = (store, rootElement) => {
  ReactDOM.render(
    <Provider store={store} >
      <Widget />
    </Provider>,
    rootElement
  )
}

/**
 * Creates the Redux store to use inside the widget.
 */
export const createStore = () =>
  mkStore(
    combineReducers(reducerList),
    {},
    applyMiddleware(thunk)
  )

// Translation table between Solbooking's culture names and BCP47 locales
const culture2bcp47 = {
  'ES': 'es-ES',
  'EN': 'en-US',
  'GB': 'en-GB',
  'DE': 'de-DE'
}

/**
 * Sets the culture automatically to the one specified in the config.
 */
export const autoSetCulture = (store) => {
  const state = store && store.getState()
  const conf = state && state.api && state.api.config
  const culture = (conf && conf.defaultCulture) || 'es-ES'
  const currency = (conf && conf.defaultCurrency) || 'EUR'
  setCulture(store, culture, currency)
}

/**
 * Sets the culture automatically to the one specified in the config.
 */
export const setCulture = (store, culture, currency) => {
  const update = {}
  if (culture) {
    // Translate culture to a BCP47 locale if needed
    const bcpCulture = culture2bcp47[culture] || culture
    // Cascade culture to find the closest one available
    const subtags = bcpCulture.split('-')
    while (subtags.length && !messages[subtags.join('-')]) subtags.pop()
    if (subtags.length) {
      update.locale = subtags.join('-')
      update.messages = messages[update.locale]
    } else {
      console.warn('No language match for:', culture)
    }
  }
  if (currency) {
    update.currency = currency
  }
  store.dispatch(updateIntl(update))
}
