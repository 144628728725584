const cultureReducer = (state = null, action) => {
  switch (action.type) {
    case 'getCalendar/success':
      const p = action.payload
      const rates = p && p.localeUserInfo
      const stats = p && p.statisticsValues
      const hasPrices = stats && stats.priceMin
      return (hasPrices && rates) || state
    default:
      return state
  }
}

export default cultureReducer
